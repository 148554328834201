<span
  fxLayout="column"
  fxFlex="0 1 900px"
  fxFlex.xs="100"
  fxFill
  fxLayoutAlign="center start"
>
  <span
    fxLayout="column"
    fxLayoutGap="20px"
    [ngClass]="{ px20px: isHandset }"
    fxFill
  >
    <span
      class="mat-headline-1 primary-color overline-margin"
      *ngIf="special.id === 28"
      >{{ "searchList.special.tippsForParents" | translate }}</span
    >
    <span
      class="mat-headline-1 primary-color overline-margin"
      *ngIf="special.id !== 28"
      >{{ "searchList.special.overline" | translate }}</span
    >

    <span class="mat-headline-2 overline-title-gap m-0">{{
      special?.title
    }}</span>
    <span
      fxLayout="row wrap"
      fxLayout.xs="column"
      fxLayoutGap="40px"
      fxFill
      fxFlexAlign="start start"
      class="special-img-border"
      fxFlexOffset="30px"
    >
      <img
        class="object-fit-cover mb-4"
        [src]="special?.imagehigh | safe : 'resourceUrl'"
        [height]="isHandset ? 204 : 262"
        [ngClass]="{ imgWidthMobile: isXs }"
        [alt]="
          !!special?.title
            ? special.title + ' - ' + ('extra.image' | translate)
            : ('extra.section' | translate) +
              ' - ' +
              ('extra.image' | translate)
        "
      />
      <span
        fxLayout="column"
        fxFlex
        fxFlex.sm="1 0 320px"
        fxFlex.xs="100"
        fxLayoutGap="20px"
        fxLayoutAlign="start"
      >
        <span class="mb1">{{ special?.summary }}</span>
        <a
          class="mt-1 d-flex text-decoration-none black"
          fxFlex="36px"
          fxLayout="row"
          fxLayoutAlign="center center"
          [routerLink]="['/special', special?.id]"
        >
          <div
            fxFlex="0 0 160px"
            fxLayoutAlign="center center"
            class="align-self-center font-weight-bold flimmo-outline-button"
            *ngIf="
              special && special.button_label !== undefined;
              else no_button_label
            "
          >
            {{ special?.button_label }}
          </div>
          <ng-template #no_button_label
            ><div
              fxFlex="0 0 160px"
              fxLayoutAlign="center center"
              class="align-self-center font-weight-bold flimmo-outline-button"
            >
              {{ "searchList.special.toSpecial" | translate }}
            </div></ng-template
          >
        </a>
      </span>
    </span>
  </span>
</span>
