import { Injectable, NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FrontpagePageComponent } from './pages/frontpage-page/frontpage-page.component';
import { ChannelsComponent } from './components/channels/channels.component';
import { HeroComponent } from './components/hero/hero.component';
import { ResultsComponent } from './components/results/results.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { SpecialComponent } from './components/special/special.component';
import { FeatureComponent } from './components/feature/feature.component';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SearchHeaderComponent } from './components/search-header/search-header.component';
import { SharedModule } from '../../shared/shared.module';
import { MatRippleModule } from '@angular/material/core';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';



@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  options = {
    touchAction: 'pan-y'
  };
}

@NgModule({
  declarations: [FrontpagePageComponent, ChannelsComponent, HeroComponent, ResultsComponent, SearchBarComponent, SpecialComponent, FeatureComponent, SearchHeaderComponent],

  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatInputModule,
    MatSelectModule,
    MatChipsModule,
    MatCheckboxModule,
    NgOptimizedImage,
    VirtualScrollerModule,
    MatRippleModule
  ],
  providers: [
    {
      // hammer instantion with custom config
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
  ]
})
export class FrontpageModule {
}
