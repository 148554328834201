<span fxLayout="row" fxLayoutAlign="center start" class="mb1 h-100" fxFlex="100">
  <span fxLayout="column" fxFlex="0 1 900px" fxFlex.sm="100" fxFlex.xs="100" fxLayoutGap="20px">
    <span fxLayout="column" class="image-ratio-container position-relative" fxFill [ngClass]="{'imgWeb': !isHandset}">
      <img width="100%" src="assets/media/hero_hoppla.svg" class="object-fit-cover absolute-zero" />
    </span>
    <span class="mat-headline-2 pt-4" fxLayout="row" fxLayoutAlign="center">{{getErrorMessage() | translate}}</span>
    <span class="mb1 mb87px" fxLayout="row" fxLayoutAlign="center">{{'error.additionalText' | translate}}</span>
    <span fxLayout="row" fxLayoutAlign="center center" class="my87px">
      <button mat-flat-button color="primary" class="text-white bt-custom" fxLayout="row" (click)="back()"
        *ngIf="hasHistory" fxLayoutAlign="center center">
        <mat-icon class="icon-custom">keyboard_arrow_left</mat-icon><span class="mat-subtitle-2">{{'error.backbutton' |
          translate}}</span>
      </button>
    </span>
    <span fxFlex="50px"></span>
  </span>
</span>
