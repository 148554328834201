import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

import { CoreRoutingModule } from './core-routing.module';
import { LayoutComponent } from './layout/layout.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { LeftDrawerComponent } from './layout/components/left-drawer/left-drawer.component';
import { RightDrawerComponent } from './layout/components/right-drawer/right-drawer.component';
import { HeaderComponent } from './layout/components/header/header.component';
import { FooterComponent } from './layout/components/footer/footer.component';
import { SharedModule } from '../shared/shared.module';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { UeberFlimmoComponent } from './pages/ueber-flimmo/ueber-flimmo.component';
import { RedirectComponent } from './pages/redirect/redirect.component';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu'



@NgModule({
  declarations: [
    LayoutComponent,
    LeftDrawerComponent,
    RightDrawerComponent,
    HeaderComponent,
    FooterComponent,
    ErrorPageComponent,
    RedirectComponent,
    UeberFlimmoComponent,
  ],
  exports: [
    LayoutComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    CoreRoutingModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTooltipModule,
    MatMenuModule,
    MatDialogModule,
    NgOptimizedImage
  ]
})
export class CoreModule {
}
