{
  "name": "flimmo-app",
  "version": "1.3.4",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "node --max-old-space-size=8192 ./node_modules/@angular/cli/bin/ng build",
    "build-prod": "npm run build -- --configuration=production",
    "test": "ng test",
    "generate": "openapi-generator-cli generate -i flimmo-openapi.yaml -g typescript-angular -o src/app/generated-api --additional-properties=ngVersion=11,npmName=restClient,supportsES6=true,npmVersion=11,withInterfaces=true,supportsES6=true",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "list-routes": "node ./scripts/list-routes.ts",
    "generate-seo": "node ./scripts/generate-seo-files.ts",
    "prerender:fix-exit": "sed -i -e \"s/}))().then().catch();/}))().then(()=>process.exit(0)).catch(()=>process.exit(1));/g\" ./node_modules/@nguniversal/builders/src/prerender/render.js",
    "prerender-dev": "npm run list-routes dev && npm run generate-seo dev && npm run build-prod",
    "prerender-prod": "npm run list-routes prod && npm run generate-seo prod && npm run build-prod",
    "prerender-stage": "npm run list-routes stage && npm run generate-seo stage && npm run build-prod",
    "serve": "node dist/flimmo-app/server/server.mjs"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.2.0",
    "@angular/cdk": "^19.2.1",
    "@angular/common": "^19.2.0",
    "@angular/compiler": "^19.2.0",
    "@angular/core": "^19.2.0",
    "@angular/fire": "^19.0.0",
    "@angular/forms": "^19.2.0",
    "@angular/material": "^19.2.1",
    "@angular/platform-browser": "^19.2.0",
    "@angular/platform-browser-dynamic": "^19.2.0",
    "@angular/platform-server": "^19.2.0",
    "@angular/router": "^19.2.0",
    "@angular/ssr": "^19.2.0",
    "@iharbeck/ngx-virtual-scroller": "^19.0.1",
    "@ngbracket/ngx-layout": "^19.0.0",
    "@ngx-translate/core": "^15.0.0",
    "@openapitools/openapi-generator-cli": "^2.7.0",
    "@yellowspot/ng-truncate": "^2.0.2",
    "ace-builds": "^1.39.0",
    "axios": "^1.8.1",
    "date-fns": "^4.1.0",
    "express": "^4.21.2",
    "font-awesome": "^4.7.0",
    "hammerjs": "^2.0.8",
    "lodash-es": "^4.17.21",
    "marked": "^15.0.7",
    "moment": "^2.30.1",
    "ngx-markdown": "^19.1.0",
    "ngx-markdown-editor": "^5.3.4",
    "raf": "^3.4.1",
    "rxjs": "^7.8.2",
    "subsink": "^1.0.2",
    "url-parse": "^1.5.10",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/architect": "^0.1902.0",
    "@angular-devkit/build-angular": "^19.2.0",
    "@angular-eslint/builder": "^19.2.0",
    "@angular-eslint/eslint-plugin": "^19.2.0",
    "@angular-eslint/eslint-plugin-template": "^19.2.0",
    "@angular-eslint/schematics": "^19.2.0",
    "@angular-eslint/template-parser": "^19.2.0",
    "@angular/cli": "^19.2.0",
    "@angular/compiler-cli": "^19.2.0",
    "@types/express": "^5.0.0",
    "@types/hammerjs": "^2.0.46",
    "@types/jasmine": "~5.1.7",
    "@types/jasminewd2": "~2.0.13",
    "@types/lodash-es": "^4.17.12",
    "@types/node": "^22.13.9",
    "@typescript-eslint/eslint-plugin": "^8.26.0",
    "@typescript-eslint/parser": "^8.26.0",
    "browser-sync": "^3.0.3",
    "codelyzer": "^6.0.2",
    "eslint": "^9.21.0",
    "firebase-tools": "^13.32.0",
    "fuzzy": "^0.1.3",
    "inquirer": "^12.4.2",
    "jasmine-core": "~5.6.0",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage-istanbul-reporter": "~3.0.3",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "open": "^10.1.0",
    "protractor": "^7.0.0",
    "safe-pipe": "~3.0.4",
    "ts-node": "~10.9.2",
    "typescript": "^5.8.2"
  }
}
