import {Component, OnDestroy, OnInit} from '@angular/core';
import {ErrorStoreService} from '../../services/error-store.service';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map, shareReplay} from 'rxjs/operators';
import {SubSink} from 'subsink';
import {TranslateService} from '@ngx-translate/core';
import {FlimmoMetaService} from '../../services/flimmo-meta.service';

@Component({
    selector: 'app-ueber-flimmo',
    templateUrl: './ueber-flimmo.component.html',
    styleUrls: ['./ueber-flimmo.component.scss'],
    standalone: false
})
export class UeberFlimmoComponent implements OnInit, OnDestroy {
  subs = new SubSink();
  isHandset = false;
  imgWeb = 'assets/media/hero_ueberflimmo.png';
  imgMobile = 'assets/media/hero_ueberflimmo.png';
  image1 = 'assets/media/btn_ueberuns_projektinformation.png';
  image2 = 'assets/media/btn_ueberuns_bewertungskriterien.png';
  image3 = 'assets/media/btn_ueberuns_konzept.png';
  image4 = 'assets/media/btn_ueberuns_herausgeber.png';
  image5 = 'assets/media/btn_ueberuns_redaktion.png';
  image6 = 'assets/media/btn_ueberuns_presse.png';

  constructor(public store: ErrorStoreService, private breakpointObserver: BreakpointObserver, private translateService: TranslateService,
              private flimmoMetaService: FlimmoMetaService) {
  }

  ngOnInit(): void {
    this.subs.sink = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
      .pipe(
        map(result => result.matches),
        shareReplay()
      ).subscribe(value => {
        this.isHandset = value;
      });
    this.flimmoMetaService.setFlimmoTitle(this.translateService.instant('static.ueber-flimmo.title'));
    this.flimmoMetaService.setDescription(this.translateService.instant('static.ueber-flimmo.description'));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }s
}
