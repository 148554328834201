{
  "layout": {
    "header": {
      "logoText": "FLIMMO",
      "sloganPart1": "Elternratgeber für",
      "sloganPart2": "TV, Streaming & YouTube",
      "fb": "Flimmo auf Facebook folgen",
      "whatsapp": "Flimmo auf Whatsapp folgen",
      "insta": "Flimmo auf Instagram folgen",
      "menuItems": {
        "about": "Über FLIMMO",
        "redtext": "Ratgeber"
      }
    },
    "footer": {
      "about": "Über FLIMMO",
      "imprint": "Impressum",
      "netiquette": "Netiquette",
      "privacy": "Datenschutzerklärung",
      "press": "Presse",
      "contact": "Kontakt",
      "copyright": "Copyright ",
      "copyrightText": "1997 - 2025 Programmberatung für Eltern e. V."
    }
  },
  "landingPage": {},
  "tv": {
    "seo": {
      "title": "FLIMMO TV Übersicht",
      "description": "Übersicht der besprochenen Sendungen und wann sie auf welchem Sender im TV laufen."
    },
    "body": {
      "repeatingText": "kein kinderrelevantes Programm",
      "repeatingTextDetail": "FLIMMO bespricht nur Sendungen, die Kinder zwischen 3 und 13 Jahren sehen"
    },
    "noResult": "Kein Programm verfügbar"
  },
  "swimlane": {
    "toTv": "Zur TV-Übersicht",
    "toRatgeber": "Alle Texte"
  },
  "frontpage": {
    "seo": {
      "title": "FLIMMO - Elternratgeber für TV, Streaming & YouTube",
      "description": "FLIMMO ist ein Elternratgeber für TV, Streaming, YouTube und Kino. FLIMMO möchte Eltern unterstützen, bei der Fülle an Angeboten den Überblick zu behalten und altersgerecht auszuwählen. Die Ampel zeigt auf einen Blick, ob ein Film, eine Serie oder ein YouTube-Kanal für Kinder geeignet ist oder nicht – und wenn ja, ab welchem Alter. Pädagogische Einschätzungen machen deutlich, was Kindern an einem Film oder einer Serie gefällt, was problematisch sein kann und worauf Eltern besonders achten sollten."
    }
  },
  "searchList": {
    "searchresults": "Suchergebnisse",
    "currentShows": "derzeit verfügbar",
    "notFound": "Keine Ergebnisse",
    "redYellowCase": "Für Rot und Gelb eingestufte Inhalte werden keine Altersempfehlungen vergeben.",
    "welcome": "Willkommen bei FLIMMO!",
    "faqTitle": "Was ist FLIMMO?",
    "faqAnswerTitle": "Elternratgeber für TV, Streaming & YouTube",
    "moreInfo": "Weitere informationen",
    "faqAnswer": "FLIMMO bespricht das Fernsehprogramm und gibt Tipps zur Fernseherziehung. Bewertet werden Sendungen, die 3- bis 13-jährige Mädchen und Jungen gerne sehen oder mit denen sie als Mitseher in Berührung kommen.",
    "placeholder": "Titel suchen",
    "chips": {
      "clearAll": "Alle zurücksetzen"
    },
    "searchLinks": {
      "tv": "Was läuft im TV?",
      "stream": "Wer streamt was?"
    },
    "carousel": {
      "overline": "Besprechungen",
      "showAll": "Alle zeigen"
    },
    "special": {
      "overline": "Themenspecial",
      "tippsForParents":"Tipps für Eltern von kleinen Kindern",
      "toSpecial": "ZUM SPECIAL"
    },
    "selectors": {
      "alter": "Alter",
      "ageOptions": {
        "3": "3+ Jahre",
        "5": "5+ Jahre",
        "7": "7+ Jahre",
        "9": "9+ Jahre",
        "11": "11+ Jahre"
      },
      "ageOptionsShort": {
        "3": "3+",
        "5": "5+",
        "7": "7+",
        "9": "9+",
        "11": "11+"
      },
      "ageOptionsAlt": {
        "3": "ab 3",
        "5": "ab 5",
        "7": "ab 7",
        "9": "ab 9",
        "11": "ab 11"
      },
      "ratingOptions": {
        "4": "FLIMMO findet’s gut",
        "3": "Grün",
        "2": "Gelb",
        "1": "Rot"
      },
      "genreOptions": {
        "Doku": "Doku",
        "Spielfilm": "Spielfilm",
        "Serie": "Serie",
        "Animationsfilm": "Animationsfilm",
        "Animationsserie": "Animationsserie",
        "Magazin,Nachrichten": "Magazin/Nachrichten",
        "Show": "Show",
        "Reality-TV": "Reality-TV"
      },
      "channelOptions": {
        "kino": "Kino",
        "netflix": "Netflix",
        "amazon": "Prime Video",
        "disney_plus": "Disney+",
        "kika": "KiKA.de",
        "toggo": "toggo",
        "ARD Mediathek": "ARD Mediathek",
        "ZDF Mediathek": "ZDF Mediathek",
        "RTLPLUS": "RTL+",
        "Joyn": "Joyn",
        "joyn": "Joyn",
        "youtube": "YouTube",
        "tiktok": "TikTok",
        "instagram": "Instagram",
        "youtubekids": "YouTube Kids",
        "tv": "TV",
        "KKA": "KiKA",
        "RTLS": "SuperRTL",
        "Disney Channel Free TV": "Disney Channel",
        "NICK": "nickelodeon",
        "RTL": "RTL",
        "RTL2": "RTLZWEI",
        "PRO7": "ProSieben",
        "SAT1": "SAT.1",
        "K1": "Kabel Eins",
        "ARD": "Das Erste",
        "ZDF": "ZDF"
      },
      "keywordOptions": {
        "2": "Action",
        "3": "Andere Länder & Kulturen",
        "4": "Anderssein",
        "5": "Anime",
        "6": "Ankommen/Flucht/Migration",
        "7": "Erwachsenwerden ",
        "8": "Fabelwesen und Magie",
        "9": "Familie ",
        "10": "Freundschaft ",
        "12": "Grusel ",
        "13": "Heldinnen & Helden",
        "14": "Historie",
        "36": "Hobby & Freizeit",
        "15": "Humor/Comedy",
        "16": "Krankheit/Tod & Trauer",
        "37": "Krieg",
        "17": "Krimi",
        "18": "Kunst/Kreativität",
        "19": "Liebe/Beziehung",
        "20": "Musik/ Tanz",
        "22": "Natur/Umwelt/Nachhaltigkeit ",
        "23": "Prinzessinnen & Ritter ",
        "24": "Religion",
        "25": "Rivalität",
        "26": "Rätsel/Raten",
        "35": "Schule",
        "38": "Schwierige Lebensverhältnisse",
        "27": "Sport",
        "28": "Technik",
        "29": "Tiere",
        "30": "Toleranz/Vorurteile",
        "31": "Trennung/Scheidung",
        "32": "Weltraum",
        "33": "Wettbewerb/Konkurrenz",
        "34": "Wissen"
      },
      "genre": "Genre",
      "keywords": "Keywords",
      "src": "Anbieter",
      "rating": "Bewertung"
    },
    "chosenTopicsText": "Kompakte Infos und praktische Tipps zu Medienerziehung, Streaming und YouTube",
    "chosenTopicsTitle": "Ratgeber"
  },
  "ytb": {
    "overline": "YouTube, TikTok & Instagram",
    "pro": "Positives",
    "con": "Negatives",
    "keywords": "Schlagwörter",
    "youtube": "YouTube",
    "youtubeKids": "YouTube Kids",
    "subscriber": "Abonnent*innen",
    "user": "Betreiber",
    "contacts": "Außerdem vertreten auf:",
    "date": "Stand: ",
    "disclaimer": "Hinweis der Redaktion",
    "linkingHint": "Auf Social Media gibt es Vieles, das nicht für Kinder geeignet ist. Bei der Nutzung beachten Sie bitte diesen ",
    "linkingHintLinkname": " Beitrag",
    "notlinkingHint": "Sie finden einen direkten Link zu dem Angebot, wenn es mit Grün bewertet wurde und verfügbar ist.",
    "noteDialog": {
      "title": "Hinweis zur Bewertung von Social-Media-Kanälen",
      "text": "Die pädagogische Einschätzung eines Kanals dient Eltern zur Orientierung. Es ist der FLIMMO-Redaktion nicht möglich, alle Videos des Kanals sowie die Kommentare darauf zu sichten. Ebenso wenig kann die Redaktion Urheberrechtsverletzungen oder unzulässige Werbung kennzeichnen bzw. berücksichtigen. Die Kanäle werden in regelmäßigen Abständen überprüft. Die Einschätzung bezieht sich auf den Stand der Inhalte zum angegebenen Datum. Änderungen nach diesem Datum konnten nicht berücksichtigt werden."
    },
    "seo": {
      "title": "FLIMMO YouTube-Besprechung",
      "description": "Besprechung des YouTube-Kanals und seiner Einstufung nach dem FLIMMO Bewertungssystem."
    },
    "nopositive": "Keine positiven Punkte",
    "nonegative": "Keine negativen Punkte",
    "tiktok": "TikTok",
    "instagram": "Instagram",
    "tiktokKids": "TikTok Kids",
    "instagramKids": "Instagram Kids"
  },
  "specialPage": {
    "named": "Themenspecial",
    "tippsForParents":"Tipps für Eltern von kleinen Kindern",
    "seo": {
      "title": "FLIMMO Themenspecial",
      "description": "Eine anlassgebundene Zusammenstellung verschiedener Inhalte."
    }
  },
  "besprechung": {
    "overline": "Besprechung",
    "approved": "FLIMMO findet's gut!",
    "flimmoComment": "FLIMMO meint",
    "flimmoButton": "SO BEWERTET FLIMMO",
    "wissen": "Wissen",
    "spannung": "Spannung",
    "vorbild": "Vorbild",
    "botschaft": "Botschaft",
    "machart": "Machart",
    "humor": "Humor",
    "toService": "WEITERLEITEN",
    "validTillDate": "Verfügbar bis",
    "linkWarning": "Sie verlassen das Angebot von FLIMMO und werden weitergeleitet. Auf Inhalte externer Websites haben wir keinen Einfluss und können dafür keine Gewähr übernehmen.",
    "swimlaneTitle": "Ähnliche Inhalte",
    "redYellowWarning": "FLIMMO leitet nicht auf Inhalte weiter, die mit Gelb oder Rot bewertet sind.",
    "noChannel": "Derzeit nicht verfügbar",
    "inKino": "Läuft im Kino",
    "seo": {
      "title": "FLIMMO Besprechung",
      "description": "Besprechung eines Bewegtbild-Inhalts und seiner Einstufung nach dem FLIMMO Bewertungssystem."
    }
  },
  "searchGenres": {
    "animation": "Animationsserie"
  },
  "redtext": {
    "unpublished": "(NICHT VERÖFFENTLICHT)"
  },
  "postfix": {
    "minutes": "Minuten"
  },
  "error": {
    "internal": "Server Fehler",
    "404": "Dieser Eintrag existiert nicht.",
    "notfound": "Diese Seite existiert nicht.",
    "additionalText": "Wir bitten dies zu entschuldigen.",
    "backbutton": "Zurück zur vorherigen Seite",
    "unhandled": "Error code unhandled!",
    "nomsg": "No message"
  },
  "common": {
    "copyrightTitle": "Bildnachweis",
    "left": "Links",
    "right": "Rechts"
  },
  "days": {
    "0": "Sonntag",
    "1": "Montag",
    "2": "Dienstag",
    "3": "Mittwoch",
    "4": "Donnerstag",
    "5": "Freitag",
    "6": "Samstag"
  },
  "static": {
    "members": {
      "title": "FLIMMO ist eine Medienkompetenz-Initiative von Landesmedienanstalten und weiteren Partnern",
      "subtitle": "Fördermitglieder"
    },
    "ueber-flimmo": {
      "title": "Über FLIMMO",
      "description": "Was ist FLIMMO? Wer steht hinter dem Projekt? Wie kommen die Einschätzungen zu Bewegtbild-Inhalten zustande? Informationen zu diesen Fragen finden Sie hier.",
      "overline": "Über FLIMMO",
      "p1": "Was ist FLIMMO? Wer steht hinter dem Projekt? Wie kommen die Einschätzungen zu Bewegtbild-Inhalten zustande? Informationen zu diesen Fragen finden Sie hier. Oder kompakt und übersichtlich im Info-Flyer - kostenlos zu bestellen unter ",
      "p2": "Sie wünschen weitere Informationen oder sind auf der Suche nach Interview-Partner*innen? Im Pressebereich finden Sie den passenden Kontakt.",
      "item1": {
        "title": "Projektinformation",
        "content": "Die wichtigsten Infos zum Projekt FLIMMO auf einen Blick."
      },
      "item2": {
        "title": "Bewertungssystem ",
        "content": "Nach welchen Kriterien wird bewertet? Was sind die Grundlagen für die Alterseinstufung?"
      },
      "item3": {
        "title": "Konzept",
        "content": "Mehr zum Ziel des medienpädagogischen Projekts, den konzeptionellen Grundlagen und der wissenschaftlichen Fundierung."
      },
      "item4": {
        "title": "Herausgeber ",
        "content": "Informationen zum Herausgeber des Projekts FLIMMO, dem gemeinnützigen Verein Programmberatung für Eltern e.V."
      },
      "item5": {
        "title": "Redaktion",
        "content": "Informationen zum JFF – Institut für Medienpädagogik in Forschung und Praxis, das mit der inhaltlichen Durchführung des Projekts betraut ist."
      },
      "item6": {
        "title": "Presse ",
        "content": "Aktuelle Pressemitteilungen und Pressekontakt"
      }
    },
    "mediaTeach": {
      "title": "Medienerziehung",
      "description": "Medienerziehung",
      "overline": "Ratgeber",
      "content": "Im Familienalltag stellen sich für Eltern viele Fragen: Welche Inhalte sind für welches Alter geeignet? Wann wird es Kindern zu viel? Welche Regeln helfen Medien sinnvoll zu nutzen? Wie werde ich den Bedürfnissen von jüngeren und älteren Geschwistern gerecht? Antworten auf diese Fragen finden Sie hier.",
      "listItem1": {
        "title": "Überblick",
        "content": "Medienerziehung in der Familie – Goldene Regeln und hilfreiche Tipps"
      },
      "ageSection": {
        "title": "Altersstufen – Was können Kinder in welchem Alter, was kommt an?"
      },
      "challenges": {
        "title": "Herausforderungen",
        "item1": "Geschwister vor dem Bildschirm",
        "item2": "Das erste Mal ins Kino",
        "item3": "Überforderung bei den Jüngsten – Zeichen erkennen und richtig reagieren",
        "item4": "Was Kindern Angst macht – und was sie dann brauchen",
        "item5": "Von Terror bis Pandemie: Kinder altersgerecht informieren",
        "item6": "Schönheitsideale und Selbstdarstellung in den Medien"
      }
    },
    "redtextStreaming": {
      "title": "Streaming",
      "description": "Streaming",
      "overline": "Ratgeber",
      "content": "Netflix, Prime Video, Joyn, Mediatheken oder Apps: Streaming-Angebote sind beliebter denn je. Die Auswahl ist riesig und dabei den Überblick zu behalten, fällt oft schwer. Welche Plattformen Kindern etwas zu bieten haben und Tipps, die beim richtigen Umgang mit Streaming helfen, finden Sie hier.",
      "listItem1": {
        "title": "Überblick",
        "content": "Streaming und Kinder – Was Eltern wissen sollten"
      },
      "section2": {
        "title": "Plattformbeschreibungen",
        "content": "Was haben die bekannten Streaming-Angebote für Kinder zu bieten? Welche Eigenproduktionen sind empfehlenswert und was sollten Eltern zu den Sicherheitseinstellungen wissen? Kompakte Infos zu Anbietern und Tipps zur Nutzung der Plattformen gibt es hier."
      },
      "boxListTitles": {
        "1": "Streaming-Anbieter",
        "2": "Mediatheken",
        "3": "SPEZIELL für Kinder"
      }
    },
    "mediaTeachGood": {
      "title": "Mediennutzung kompetent begleiten",
      "description": "Mediennutzung kompetent begleiten",
      "overline": "Ratgeber",
      "content": "Lieblingsserie, Filmabend, YouTube-Videos: Bewegte Bilder sind im Familienalltag allgegenwärtig. Was ist für welches Alter das Richtige? Wieviel Medienzeit ist in Ordnung? Damit Kinder lernen, mit Medien richtig umzugehen und sie sinnvoll zu nutzen, brauchen sie Eltern, die dranbleiben und sich auskennen. Hier die wichtigsten Informationen auf einen Blick.",
      "listItem1": {
        "title": "Medienerziehung",
        "content": "Was können Kinder in welchem Alter anschauen? Wann wird es zu viel? Regeln und Tipps zur Mediennutzung in der Familie"
      },
      "listItem2": {
        "title": "Streaming",
        "content": "Infos zu Streaming-Plattformen, Tipps und Wissen rund um Streaming in der Familie"
      },
      "listItem3": {
        "title": "YouTube",
        "content": "Influencer*innen, Challenges, YouTube Kids: Was Eltern über YouTube wissen sollten"
      }
    },
    "redtextYoutube": {
      "title": "YouTube",
      "description": "YouTube",
      "overline": "Ratgeber",
      "content": "YouTube ist kein Kinderkanal. Von lustigem Katzenvideo bis hin zu Gewalt und Propaganda ist (fast) alles vertreten. Doch viele Kinder und Jugendliche können nicht genug von ihren Lieblings-YouTuber*innen bekommen. Wie Eltern mit diesem Zwiespalt umgehen können und was es bei YouTube generell zu beachten gilt, erfahren Sie hier.",
      "listItem1": {
        "title": "Überblick",
        "content": "YouTube und Kinder – Faszination, Risiken und hilfreiche Tipps"
      },
      "ageSection": {
        "title": "Altersstufen – was können Kinder in welchem Alter, was kommt an?"
      },
      "challenges": {
        "title": "Gut zu wissen",
        "item1": "YouTube Kids – Die bessere Alternative",
        "item2": "Einstellungen – Das ist möglich",
        "item3": "Geschäftsmodell – So fließt das Geld",
        "item4": "Informieren mit YouTube – Gewusst wie",
        "item5": "Mini-Influencer*innen – Kinder vor der Kamera",
        "item6": "YouTube-Stars – Idole von heute"
      }
    }
  },
  "legendDialog": {
    "section1": {
      "title": "FLIMMO bewertet Inhalte, die Kinder zwischen 3 und 13 Jahren sehen",
      "item1": "Diese Inhalte sind ab dem angegebenen Alter geeignet und kommen gut bei Kindern an. Gibt es Elemente, die jüngere Kinder überfordern könnten, wird gesondert darauf hingewiesen.",
      "item2": "Aus pädagogischer Sicht gibt es problematische Aspekte. Eltern sollten im Blick behalten, wie Kinder damit umgehen und gegebenenfalls gegensteuern.",
      "item3": "Es gibt Elemente, die Kinder überfordern, verunsichern oder ängstigen können. Unabhängig vom Alter sind solche Inhalte für Kinder nicht geeignet."
    },
    "section2": {
      "title": "FLIMMO findet’s gut",
      "item1": "Diese Inhalte haben aus Sicht der FLIMMO-Redaktion Kindern viel zu bieten und überzeugen durch besondere Qualität und Originalität."
    },
    "button": "Weitere infos"
  },
  "optionsDialog": {
    "button1": "ANZEIGEN",
    "button2": "ZURÜCKSETZEN"
  },
  "extra":{
    "section":"Abschnitt",
    "image":"Bild",
    "landingImage":"Landebild",
    "socialmedia":"Soziale Medien"
  }
}
