import {Component} from '@angular/core';

@Component({
    selector: 'app-right-drawer',
    templateUrl: './right-drawer.component.html',
    styleUrls: ['./right-drawer.component.scss'],
    standalone: false
})
export class RightDrawerComponent  {
}
