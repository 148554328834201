
<span fxLayout="row" fxLayoutAlign="center start" class="mb1 h-100" fxFlex="100">
  <span fxLayout="column" fxFlex="0 1 900px" fxFlex.sm="100" fxFlex.xs="100" fxLayoutGap="20px">
    <span class="mat-headline-2 pt-4" fxLayout="row" fxLayoutAlign="center">Sie wurden weitergeleitet.</span>
    <span fxLayout="row" fxLayoutAlign="center center" class="my87px">
      <button mat-flat-button color="primary" class="primary-color bt-custom" fxLayout="row"
         fxLayoutAlign="center center" (click)="close()">
        <span class="mat-subtitle-2">Fenster schließen</span>
      </button>
    </span>
    <span fxFlex="50px"></span>
  </span>
</span>
