import { Component, Input } from '@angular/core';
import { PreviewItem } from '../../../../generated-api';

@Component({
  selector: 'app-special',
  templateUrl: './special.component.html',
  styleUrls: ['./special.component.scss'],
  standalone: false,
})
export class SpecialComponent {
  @Input() special: PreviewItem;
  @Input() isHandset: boolean;
  @Input() isXs: boolean;
}
