import {
  HammerModule,
  provideClientHydration,
  withEventReplay,
  withI18nSupport,
} from '@angular/platform-browser';
import {
  NgModule,
  LOCALE_ID,
  APP_ID,
  PLATFORM_ID,
  Inject,
} from '@angular/core';
import {
  CommonModule,
  isPlatformBrowser,
  registerLocaleData,
} from '@angular/common';
import localeDe from '@angular/common/locales/de';

registerLocaleData(localeDe);

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { environment } from '../environments/environment';
import { apiConfigFactory } from './core/helpers/factories/generatedApiConfigFactory';
import { ApiModule, BASE_PATH } from './generated-api';
import {
  MarkdownModule,
  MARKED_OPTIONS,
  MarkedOptions,
  MarkedRenderer,
} from 'ngx-markdown';
import { SafePipe } from 'safe-pipe';
import {
  MissingTranslationHandler,
  TranslateModule,
} from '@ngx-translate/core';
import { ErrorInterceptorService } from './core/interceptors/error-interceptor.service';
import { MyMissingTranslationHandler } from './core/services/missing-translation-handler.service';
import { isAbsoluteLinkFast } from './shared/functions/utils';
import { FrontpageModule } from './modules/frontpage/frontpage.module';
import { GLOSSARY_IDENTIFIER } from './core/services/markdown-link-handler.service';
import { MatListModule } from '@angular/material/list';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { marked } from 'marked';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    SharedModule,
    BrowserAnimationsModule,
    CoreModule,
    HammerModule,
    FrontpageModule,
    ApiModule.forRoot(apiConfigFactory),
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useFactory: markedOptionsFactory,
      },
    }),
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
      },
    }),
    AppRoutingModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    VirtualScrollerModule,
    SafePipe,
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideClientHydration(withEventReplay(), withI18nSupport()),
    { provide: BASE_PATH, useValue: environment.apiUrl },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: APP_ID, useValue: 'serverApp' },
  ],
  exports: [MatIconModule],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(@Inject(PLATFORM_ID) platformId: Object) {
    if (isPlatformBrowser(platformId)) {
      if (typeof global !== 'undefined') {
        global.marked = marked;
      }
      if (typeof window !== 'undefined') {
        (window as any).marked = marked;
      }
    }
  }
}

function isGlossaryLink(href: string): boolean {
  return href.includes(GLOSSARY_IDENTIFIER);
}

export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  const hRenderer = renderer.html;
  renderer.html = (text) => {
    try {
      let html = hRenderer.call(renderer, text);
      if (html.startsWith('<details white>\n<summary>')) {
        const title = html.substring(25, html.indexOf('</summary'));
        html = html.replace(title, '');
        html = html.replace('<details white>', '<details class="bg-white">');
        html = html.replace(
          '<summary>',
          `<summary><h4>${title}</h4><i class="material-icons custom-icon">keyboard_arrow_down</i>`
        );
      } else if (html.startsWith('<details>\n<summary>')) {
        const title = html.substring(19, html.indexOf('</summary'));
        html = html.replace(
          '<details>',
          '<details class="mat-app-background-1">'
        );
        html = html.replace(title, '');
        html = html.replace(
          '<summary>',
          `<summary><h4>${title}</h4><i class="material-icons custom-icon">keyboard_arrow_down</i>`
        );
      }
      return html;
    } catch (error) {
      return text;
    }
  };

  renderer.heading = ({ depth, tokens, text }) => {
    try {
      if (depth === 1) {
        return `<h1 class="${'mat-headline-1'}">${marked.parseInline(
          text
        )}</h1>`;
      } else if (depth === 2) {
        return `<h2 class="${'mat-headline-2 mb-16'}">${marked.parseInline(
          text
        )}</h2>`;
      } else if (depth === 3) {
        return `<h3 class="${'mat-subtitle-1'}">${marked.parseInline(
          text
        )}</h3>`;
      } else if (depth === 4) {
        return `<h4 class="${'mat-subtitle-1'}">${marked.parseInline(
          text
        )}</h4>`;
      } else if (depth === 6) {
        return `<h6>${marked.parseInline(text)}</h6>`;
      }
      if (tokens.length < 3) {
        if (tokens[0].raw.trim() === '*') {
          return `<h5 class="${'mat-headline-1 mb-0'}"><em>* </em>${marked.parseInline(
            tokens[1].raw
          )}</h5>`;
        }
      }
      return `<h5 class="${'mat-headline-1'}">${marked.parseInline(text)}</h5>`;
    } catch (error) {
      return text;
    }
  };
  renderer.link = ({ href, title, text, raw, tokens, type }) => {
    if (text.includes('Slideshow')) {
      const images = title?.split(',') ?? [];
      let htmlText = `
      <div class="slideshow-container">
      <div class="slideshow" id="slideshow" data-component="slideshow">`;

      for (let i = 0; i < images.length; i++) {
        htmlText +=
          `<div id="slide" class="slide">
                     <img height=320 width=320 src="` +
          images[i] +
          `" alt="">
                    </div>`;
      }

      htmlText += `
        <img class="slide-button-left" src="assets/media/hover_arrows_hero_left.svg" height="41" width="27"/>
        <img class="slide-button-right" src="assets/media/hover_arrows_hero_right.svg" height="41" width="27"/>`;

      htmlText += `<div class="slide-indicators">`;
      htmlText += `<span class="slide-indicators-bg">`;

      for (let i = 0; i < images.length; i++) {
        htmlText +=
          `<span class="slide-indicator-touch" touch-slide=` +
          i +
          `> <span class="slide-indicator" data-slide=` +
          i +
          `></span></span>`;
      }
      htmlText += `</span></div>`;

      htmlText += `</div></div>`;

      return htmlText;
    }

    if (tokens.length === 1 && tokens[0].type === 'image') {
      return `<a target="_blank" href="${href}"><img src="${tokens[0].href}"/></a>`;
    }

    if (text.includes('<img')) {
      if (href?.includes('flimmo.de')) {
        return '<a href="' + href + '">' + text + '</a>';
      } else {
        return '<a target="_blank" href="' + href + '">' + text + '</a>';
      }
    }

    if (text.includes('Download-Button')) {
      return (
        `
        <a class="mt-1 text-decoration-none black" href="` +
        href?.substring(0, href.length - 1) +
        `" target="_blank">
        <div class="align-content-center download-button font-weight-bold">
          <table>
            <tr>
              <td>
                <i class="material-icons custom-icon-white">download</i>
              </td>
              <td>
                <font class="mat-subtitle-2" color="#FFFFFF">` +
        title +
        `</font>
              </td>
            </tr>
          </table>
        </div>
      </a>`
      );
    }

    if (href && isAbsoluteLinkFast(href)) {
      return `<a target="_blank" rel="nofollow" href="${href}">${text}<i class="material-icons custom-material-icon-open-in-new">open_in_new</i><\/a>`;
    }

    if (href && isGlossaryLink(href)) {
      return `<a href="${href}">${text} <i class="material-icons custom-material-icon-info">info_outlined</i><\/a>`;
    }

    if (text.includes('Big-Button-Line')) {
      const imageLink = text.substring(text.indexOf('|') + 1);
      const titletx = title?.substring(0, title.indexOf('|'));
      const texttx = title?.substring(title.indexOf('|') + 1);

      if (href?.startsWith('ext:')) {
        return (
          `
        <a target="_blank" class="button-link" href="` +
          href.substring(4, href.length - 1) +
          `">
          <div>
            <table class="bg-white big-button-link-table">
              <tr>
                <td class="button-link-td-img">
                  <img class="border-radius-4px big-button-img" src="` +
          imageLink +
          `">
                </td>
                <td class="button-link-td-text">
                  <p>
                    <span class="mat-subtitle-2"><font color="#000000">` +
          titletx +
          `</font></span>
                    <div class="mb1 listItemWidth"><font color="#000000">` +
          texttx +
          `</font></div>
                  </p>
                </td>
                <td class="button-link-td-icon">
                  <i class="material-icons custom-material-icon-open-in-new">open_in_new</i>
                </td>
              </tr>
            </table>
          </div>
        </a>
        `
        );
      } else {
        return (
          `
      <a class="button-link" href="` +
          href?.substring(0, href.length - 1) +
          `">
        <div>
          <table class="bg-white big-button-link-table">
            <tr>
              <td class="button-link-td-img">
                <img class="border-radius-4px big-button-img" src="` +
          imageLink +
          `">
              </td>
              <td class="button-link-td-text">
                <p>
                  <span class="mat-subtitle-2"><font color="#000000">` +
          titletx +
          `</font></span>
                  <div class="mb1 listItemWidth"><font color="#000000">` +
          texttx +
          `</font></div>
                </p>
              </td>
              <td class="button-link-td-icon">
                <i class="material-icons custom-material-keyboard_arrow_right">keyboard_arrow_right</i>
              </td>
            </tr>
          </table>
        </div>
      </a>
      `
        );
      }
    }

    if (text.includes('Text-Button-Line')) {
      const color = text.substring(text.indexOf('|') + 1);

      let style = 'bg-white';
      if (color === 'grey') {
        style = 'mat-app-background-1';
      }

      return (
        `
      <a class="button-link" href="` +
        href?.substring(0, href.length - 1) +
        `">
        <div>
          <table class="` +
        style +
        ` text-button-link-table">
            <tr >
              <td class="px10px">
                <span class="mb1"><font color="#000000">` +
        title +
        `</font></span>
              </td>
              <td class="button-link-td-icon">
                <i class="material-icons custom-material-keyboard_arrow_right ">keyboard_arrow_right</i>
              </td>
            </tr>
          </table>
        </div>
      </a>`
      );
    }

    if (text.includes('Rounded-Button')) {
      return (
        `
      <a class="mt-1 text-decoration-none black" href="` +
        href?.substring(0, href.length - 1) +
        `">
        <div class="align-content-center font-weight-bold flimmo-outline-button">
          <font color="#000000">` +
        title +
        `</font>
        </div>
      </a>
      `
      );
    }

    // if (text.includes("Exp-Text")) {
    //   const color = text.substring(text.indexOf('|') + 1);

    //   let style = 'bg-white'
    //   if (color === 'grey') {
    //     style = 'mat-app-background-1';
    //   }
    //   const titletx = title.substring(0, title.indexOf('|'));
    //   const texttx = title.substring(title.indexOf('|') + 1);

    //   return `
    //   <details class="`+ style + `">
    //     <summary>
    //       <h3>`+ titletx + `</h3>
    //       <i class="material-icons custom-icon">keyboard_arrow_down</i>
    //     </summary>
    //     <p>`+ texttx + `</p>
    //   </details>`;
    // }

    if (text.startsWith('*') && text.endsWith('*') && text.length > 2) {
      console.log(text)
      return `<a href="${href}"><em>${text.slice(1,-1)}</em></a>`;
    }
    return `<a href="${href}">${text}</a>`;
  };

  return {
    renderer,
  };
}
