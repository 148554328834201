<span class="w-100 hero-container" fxLayout="row" #container>
  <ng-container *ngIf="getSlide(activeIdx) as slide; else placeholder">
    <span
    class="position-relative w-100 h-100" [ngStyle]="{
    'transition': 'background-image 0.5s linear',
    'background-image': 'url(' + slide.imagehigh + ')',
    'background-size': 'cover',
    'background-position': 'center'}"
          (swiperight)="restartInterval() && prev()"
          (swipeleft)="restartInterval() && next()">
      <div fxFill fxLayout="row" fxLayoutAlign="center end" (click)="goTo(slide)"
           class="position-relative cursor">
        <div class="position-absolute h-50 w-100 top-50 overlay-shade zindex-overlay"></div>
        <div fxLayout="column" fxFlex="0 1 900px" fxFlex.sm="1 1 100" fxFlex.xs="1 1 100">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.sm="start end" fxLayoutAlign.xs="start end"
               fxFill>
            <div fxFlex="60px" fxFlex.xs="20px"></div>
            <div fxLayout="column" fxFlex fxFill fxLayoutGap="1px" class="zindex-content">
              <span class="mat-headline-1 text-white">{{slide.topos | uppercase}}</span>
              <span class="slide-title f-hero-headline small-lines">{{slide.title}}</span>
              <span class="slide-title f-hero-subline">{{slide.summary}}</span>
              <div fxFlex="55px"></div>
            </div>
            <div fxFlex="60px" fxFlex.xs="10px"></div>
          </div>
        </div>
      </div>
        <img src="assets/media/hover_arrows_hero_left.svg" [alt]="'common.left' | translate"
             class="left-arrow position-absolute absolute-zero clickable" height="82" width="54"
             (click)="$event.stopPropagation()  || restartInterval() && prev()"/>
        <img src="assets/media/hover_arrows_hero_right.svg" [alt]="'common.right' | translate" height="82" width="54"
             class="right-arrow position-absolute absolute-zero clickable"
             (click)="$event.stopPropagation() || restartInterval() && next()"/>
        <div #indicators fxLayout="row" class="position-absolute indicators" fxLayoutGap="5px">
          <span class="indicator" *ngFor="let s of slides; let i = index"
                [ngStyle]="{background: activeIdx === i ? '#FF522E' : 'white'}"></span>
        </div>
    </span>

    <!--CACHE IMAGES BEFORE PAGING-->
    <img *ngFor="let slideImage of slides; let i = index" [src]="slideImage.imagehigh" hidden fetchpriority="high" [loading]="index === 0 ?'eager' : 'lazy'">
  </ng-container>
  <ng-template #placeholder>
    <span
      *ngIf="placeholderPreviewItem"
      class="position-relative w-100 h-100" [ngStyle]="{
      'background-image': 'url(' + placeholderPreviewItem.imagehigh + ')',
      'background-size': 'cover',
      'background-position': 'center'}">
      <div fxFill fxLayout="row" fxLayoutAlign="center end"
           class="position-relative cursor">
        <div class="position-absolute h-50 w-100 top-50 overlay-shade zindex-overlay"></div>
        <div fxLayout="column" fxFlex="0 1 900px" fxFlex.sm="1 1 100" fxFlex.xs="1 1 100">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.sm="start end" fxLayoutAlign.xs="start end"
               fxFill>
            <div fxFlex="60px" fxFlex.xs="20px"></div>
            <div fxLayout="column" fxFlex fxFill fxLayoutGap="1px" class="zindex-content">
              <span class="mat-headline-1 text-white">{{placeholderPreviewItem.topos | uppercase}}</span>
              <span class="slide-title f-hero-headline small-lines">{{placeholderPreviewItem.title}}</span>
              <span class="slide-title f-hero-subline">{{placeholderPreviewItem.summary}}</span>
              <div fxFlex="55px"></div>
            </div>
            <div fxFlex="60px" fxFlex.xs="10px"></div>
          </div>
        </div>
      </div>
    </span>
  </ng-template>
</span>
