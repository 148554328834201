<span fxLayout="row" [ngClass]="{'px20px': isHandset$ | async}" fxLayoutGap="20px" class="results-title w-100"
      fxLayoutAlign="space-between">

      <h1 *ngIf="searchParams.src && searchParams.src.includes('kino')" class="primary-color mat-headline-1 mb-0 mt-0">
            Aktuell im Kino: {{ (rowCount > 4 && (isHandset$ | async) === true) || rowCount > 10 ? rowCount : '' }}
            {{'searchList.searchresults' | translate}}
      </h1>


      <span *ngIf="!searchParams.src || !searchParams.src.includes('kino')" class="primary-color mat-headline-1 mb-0 mt-0">
            <span *ngIf="(rowCount > 4 && (isHandset$ | async) === true) || rowCount > 10">
                  {{rowCount}}
            </span>

            {{'searchList.searchresults' |
            translate}}
      </span>

      <mat-checkbox class="cb" *ngIf="currentShowsFilterVisible" disableRipple color="primary"
            [checked]="currentShowsChecked" (change)="filterCurrentShows($event)">{{'searchList.currentShows' |
            translate}}
      </mat-checkbox>
</span>
