<mat-toolbar fxLayout="row" class="h-100 py-4 mat-app-background-4" fxLayoutAlign="center center"
  [ngClass]="{'px20px': isHandset}">
  <div fxLayoutGap="20px" fxLayout="column" fxFlex="0 1 900px" fxFlex.sm="0 1 100" fxFlex.xs="0 1 100">

    <span fxLayout="row" fxLayoutAlign="space-between start" fxFill>
      <span fxLayout="column">
        <span fxFlex="35" class="text-decoration-none black text-wrap outline-none" fxLayout="row"
          [ngClass]="{'mb-3': isHandset}" fxFlexAlign="start start" fxLayoutGap="10px">
          <img ngSrc="assets/media/Flimmo_Logo_Footer.svg" width=140 height=25
            [alt]="'layout.header.logoText' | translate">
          <span fxLayout="column" class="f-slogan footer-text-color" [ngStyle]="{'min-width.px':150}">
            <div>{{'layout.header.sloganPart1' | translate}}</div>
            <div>{{'layout.header.sloganPart2' | translate}}</div>
          </span>
        </span>
      </span>
    </span>

    <!-- <span fxLayout="column" class="footer-text-color" fxLayoutGap="10px" fxLayoutAlign="start center">
      <span fxLayout="row" fxLayoutGap="60px"> -->
        <span fxLayout="row wrap" fxLayoutGap="20px" class="text-right letter-spacing-normal" fxLayoutAlign="start center">
          <a class="f-footer-link text-decoration-none footer-text-color link-z"
            routerLink="/redtext/101221">{{'layout.footer.imprint' | translate}}</a>
          <a class="f-footer-link text-decoration-none footer-text-color link-z"
            routerLink="/redtext/101227">{{'layout.footer.privacy' | translate}}</a>
          <a class="f-footer-link text-decoration-none footer-text-color link-z"
            routerLink="/redtext/101523">{{'layout.footer.netiquette' | translate}}</a>
          <a class="f-footer-link text-decoration-none footer-text-color link-z"
            routerLink="/ueber-flimmo">{{'layout.footer.about' | translate}}</a>
          <a class="f-footer-link text-decoration-none footer-text-color link-z"
            routerLink="/redtext/101226">{{'layout.footer.contact' | translate}}</a>
          <a class="f-footer-link text-decoration-none footer-text-color link-z"
            routerLink="/redtext/101228">{{'layout.footer.press' | translate}}</a>
        </span>
      <!-- </span>
    </span> -->

    <span fxLayout="row" class="footer-text-color footer-z" fxLayoutAlign="start center letter-spacing-normal"      >
      <span [matTooltip]="getVersion()" class="f-copyright letter-spacing-normal">{{'layout.footer.copyright' | translate}}</span>
      <span class="f-copyright-text ch1 letter-spacing-normal">{{'layout.footer.copyrightText' | translate}}</span>
    </span>
  </div>
</mat-toolbar>
