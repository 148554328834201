<span fxLayout="row" fxFlex="100" fxLayoutAlign="center start">
  <span
    fxLayout="column"
    class="position-relative lane-body"
    fxFlex="0 1 900px"
    fxFlex.xs="100"
    fxFlex.sm="100"
  >
    <span fxLayout="column" fxLayoutAlign="start start">
      <span
        class="mat-headline-1 primary-color mb10px"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxFill
        [ngClass]="{ px20px: isHandset }"
        >{{ title }}</span
      >
      <span
        fxLayout="row"
        fxLayoutAlign="start center"
        fxFill
        [fxLayoutGap]="boxGapPx + 'px'"
        class="overflow-auto scrollbar-off"
        #scrollContainer
      >
        <div
          *ngIf="isHandset"
          [ngStyle.sm]="{ 'width.px': 5 }"
          [ngStyle.xs]="{ 'width.px': 5 }"
        ></div>
        <a
          [routerLink]="['/']"
          [queryParams]="{ src: b.channelId }"
          *ngFor="let b of channels"
          [ngStyle]="{ 'width.px': boxWidthPx,  'height.px': boxWidthPx }"
        >
          <img
            height="80"
            width="80"
            class="box text-center clickable"
            [ngStyle]="{ 'width.px': boxWidthPx }"
            [src]="b.assetLink"
            [alt]="getAltTranslationString(b.channelId) | translate"
            loading="lazy"
            fetchprioritiy="low"
        /></a>
        <div
          *ngIf="isHandset"
          [ngStyle.sm]="{ 'width.px': 20 }"
          [ngStyle.xs]="{ 'width.px': 20 }"
          [innerHTML]="'&nbsp;'"
        ></div>
      </span>
    </span>
    <img
      src="assets/media/hover_arrows_hero_left.svg"
      *ngIf="leftVisible()"
      class="left-pos clickable"
      (click)="scrollLeft(scrollContainer)"
      height="48"
      width="31"
      [alt]="'common.left' | translate"
      loading="lazy"
      fetchprioritiy="low"
    />
    <img
      src="assets/media/hover_arrows_hero_right.svg"
      *ngIf="rightVisible()"
      class="right-pos clickable"
      (click)="scrollRight(scrollContainer)"
      height="48"
      width="30"
      [alt]="'common.right' | translate"
      loading="lazy"
      fetchprioritiy="low"
    />
  </span>
</span>
