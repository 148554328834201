import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorStoreService {
  private readonly _errors = new BehaviorSubject<any>(null);
  readonly errors$ = this._errors.asObservable();

  get errors(): any {
    return this._errors.getValue();
  }

  set errors(val: any) {
    this._errors.next(val);
  }
  constructor() { }
}
