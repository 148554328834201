import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanDeactivate,
  RouterStateSnapshot,
} from '@angular/router';
import { Meta } from '@angular/platform-browser';
import {
  isPlatformBrowser,
} from '@angular/common';
@Injectable({
  providedIn: 'root',
})
export class CanonicalGuard implements CanActivate, CanDeactivate<undefined> {
  constructor(
    private meta: Meta,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (isPlatformBrowser(this.platformId)) {
      const canonicalTag = this.meta.getTag('rel="canonical"');
      if (canonicalTag) {
        this.meta.updateTag({ rel: 'canonical', href: window.location.origin });
      } else {
        this.meta.addTag({ rel: 'canonical', href: window.location.origin });
      }
    }
    return true;
  }
  canDeactivate() {
    if (isPlatformBrowser(this.platformId)) {
       this.meta.removeTag("rel=canonical");
    }
    return true;
  }
}
