import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SubSink } from 'subsink';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SearchStoreService } from '../../stores/search-store.service';
import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';

@Component({
    selector: 'app-search-header',
    templateUrl: './search-header.component.html',
    styleUrls: ['./search-header.component.scss'],
    standalone: false
})
export class SearchHeaderComponent implements OnInit, OnDestroy {
  subs = new SubSink();
  private queryParams: Params;
  currentShowsChecked = SearchStoreService.isCurrentShowsChecked;
  currentShowsFilterVisible = false;
  rowCount = 0;
  searchParams: any;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public store: SearchStoreService,
    private breakpointObserver: BreakpointObserver) {
  }

  ngOnInit(): void {
    this.subs.sink = this.activatedRoute.queryParams.subscribe(value => {
      this.currentShowsChecked = SearchStoreService.isCurrentShowsChecked;
      this.queryParams = value;
    });

    this.subs.sink = this.store.searchParams$.subscribe(value => {
      this.currentShowsChecked = SearchStoreService.isCurrentShowsChecked;
      this.currentShowsFilterVisible = !(value?.src?.length > 0); // || SearchStoreService.isCurrentShowsChecked;
      this.searchParams = value;
    });

    this.subs.sink = this.store.rowCount$.subscribe(value => {
      this.rowCount = value;
    });
  }

  filterCurrentShows($event: MatCheckboxChange): void {
    const newParams = { ...this.queryParams };
    newParams.available = $event.checked;
    SearchStoreService.isCurrentShowsChecked = $event.checked;
    SearchStoreService.isCurrentShowsCheckedDirty = true;
    this.router.navigate([], { queryParams: newParams });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
