import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { ChannelsScrollerModel } from '../../helpers/channels-scroller.model';

@Component({
  selector: 'app-channels',
  templateUrl: './channels.component.html',
  styleUrls: ['./channels.component.scss'],
  standalone: false,
})
export class ChannelsComponent implements AfterViewInit {
  private nrOfFittingChannels = 7;

  boxWidthPx = 100;
  boxGapPx = 15;

  @Input() title: any;
  @Input() channels: ChannelsScrollerModel[];
  @Input() isHandset = false;
  @ViewChild('scrollContainer') sc: ElementRef;

  private leftAtLimit = true;
  private rightAtLimit = false;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.calcNrOfFittingChannels();
    this.recalcLimits();
  }

  recalcLimits(): void {
    const el = this.sc.nativeElement;
    if (el.scrollLeft === 0) {
      this.leftAtLimit = true;
      this.rightAtLimit = this.channels.length <= this.nrOfFittingChannels;
    } else if (el.scrollLeft >= el.scrollWidth) {
      this.rightAtLimit = true;
      this.leftAtLimit = false;
    }
  }

  scrollLeft(scrollContainer: HTMLSpanElement): void {
    scrollContainer.scroll({
      left: -scrollContainer.clientWidth + scrollContainer.scrollLeft,
      behavior: 'smooth',
    });
    this.leftAtLimit =
      -scrollContainer.clientWidth + scrollContainer.scrollLeft <= 0;
    this.rightAtLimit = false;
  }

  scrollRight(scrollContainer: HTMLSpanElement): void {
    const maxScrollLeft =
      this.sc.nativeElement.scrollWidth - this.sc.nativeElement.clientWidth;
    scrollContainer.scroll({
      left: scrollContainer.clientWidth + scrollContainer.scrollLeft,
      behavior: 'smooth',
    });
    this.rightAtLimit =
      scrollContainer.clientWidth + scrollContainer.scrollLeft >= maxScrollLeft;
    this.leftAtLimit = false;
  }

  leftVisible(): boolean {
    return !this.leftAtLimit;
  }

  rightVisible(): boolean {
    return this.channels && this.channels.length > this.nrOfFittingChannels
      ? !this.rightAtLimit
      : false;
  }

  getAltTranslationString(channelId: string): string {
    return 'searchList.selectors.channelOptions.' + channelId;
  }

  ngAfterViewInit(): void {
    this.calcNrOfFittingChannels();
  }

  private calcNrOfFittingChannels() {
    this.nrOfFittingChannels = Math.floor(
      this.sc.nativeElement.clientWidth / (this.boxWidthPx + 15)
    );
  }
}
