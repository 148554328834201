import {Component, Input} from '@angular/core';
import {Feature} from '../../../../generated-api';

@Component({
    selector: 'app-feature',
    templateUrl: './feature.component.html',
    styleUrls: ['./feature.component.scss'],
    standalone: false
})
export class FeatureComponent {
  private _feature: Feature;
  @Input() set feature(f: Feature) {
    this._feature = f;
  }

  get feature(): Feature {
    return this._feature;
  }

  @Input() isHandset: boolean;
  @Input() isXs: boolean;

}
