import {Injectable} from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {ErrorStoreService} from '../services/error-store.service';
import {Router} from '@angular/router';
import {RouterEventsService} from '../services/router-events.service';
import {Location} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService {

  ERROR_URL_EXCEPTIONS = [
    'api/v2/glossar',
    'api/v2/medialist'
  ];

  constructor(private errorStore: ErrorStoreService, private router: Router,
              private routerEventsService: RouterEventsService, private location: Location) {
  }

  private isInExceptions(url: string): boolean {
    return this.ERROR_URL_EXCEPTIONS.some(value => {
      return url.includes(value);
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return next.handle(req)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
            let errorMessage = '';
            if (error.error instanceof ErrorEvent) {
              errorMessage = `Error: ${error.error.message}`;
            } else {
              errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            }
            this.errorStore.errors = {code: error.status};
            if (!this.isInExceptions(error.url)) {
              this.routerEventsService.previousRoutePath.next(this.router.url);
              this.router.navigate(['error'], {replaceUrl: true});
              return throwError(errorMessage);
            }
          }
        )
      );
  }
}
