import { IPageInfo, VirtualScrollerComponent } from '@iharbeck/ngx-virtual-scroller';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchService } from '../../services/search.service';
import { SearchStoreService } from '../../stores/search-store.service';
import { FlimmoMetaService } from '../../../../core/services/flimmo-meta.service';
import { debounceTime } from 'rxjs/operators';
import { PreviewItem, Source } from '../../../../generated-api';
import { Subject } from 'rxjs';
import { SubSink } from 'subsink';
import * as Constants from '../../../../core/helpers/constants/constants';
import ItemclassEnum = PreviewItem.ItemclassEnum;


@Component({
    selector: 'app-results',
    templateUrl: './results.component.html',
    styleUrls: ['./results.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ResultsComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  fillerCards: any[] = [];
  itemsPerRow = 0;
  private LOAD_TRESHOLD = 1000;
  private CONTAINER_TRESHOLD = 20;
  private noMoreResults = false;

  currentShowsFilterVisible = false;

  constructor(private activatedRoute: ActivatedRoute,
    private searchService: SearchService,
    private store: SearchStoreService,
    public flimmoMeta: FlimmoMetaService,
    private router: Router,
    private cdr: ChangeDetectorRef) {

    this.subs.sink = this.changeSize
      .asObservable()
      .pipe(
        debounceTime(1000)
      )
      .subscribe(val => {
        this.calculateNumberOfItemsPerRow();
      });
  }

  @Input() pageTopOffset = 0;
  @Input() results: PreviewItem[];
  @ViewChild('scroll') vs: VirtualScrollerComponent;
  @ViewChild('container') cardContainer: ElementRef;

  private changeSize = new Subject();

  sic = ItemclassEnum;
  subs = new SubSink();
  queryParams;
  offset = 0;
  limit = SearchService.LIMIT;

  cardWidthMobile = 160;
  cardWidth = 168;

  cardHeight = 200;
  cardHeightMobile = 200;
  verticalPadding = 15;

  fillerMobile = 15;
  filler = 15;
  titleHeight = 50;
  @Input() loading = false;
  loadingChunk = false;
  @Input() isHandset: any;

  readonly PLACEHOLDER_IMAGE_WITH_PATH = Constants.PLACEHOLDER_IMAGE_WITH_PATH;
  fillerWidth = 0;

  @HostBinding('style.height') height = 'auto';
  heightNr = 0;

  ngAfterViewInit(): void {
    this.calculateNumberOfItemsPerRow();
    this.calculateComponentHeight();
    setTimeout(() => {
      this.scrollToLastPosition();
    }, 100);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.changeSize.next(event);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event): void {
    if (this.heightNr > 0 && window.pageYOffset > this.heightNr - this.LOAD_TRESHOLD) {
      this.loadNextChunk();
    }

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.subs.sink = this.store.noMoreResults$.subscribe(value => {
      if (value) {
        this.noMoreResults = value;
        this.cdr.detectChanges();
        this.cdr.markForCheck();
      }
    });

    this.subs.sink = this.store.searchParams$.subscribe(value => {
      this.currentShowsFilterVisible = !(value?.src?.length > 0);
    });

    this.subs.sink = this.store.offset$.subscribe(value => {
      this.offset = value;
    });

    this.subs.sink = this.activatedRoute.queryParams.subscribe(value => {
      if (!(value.q?.length >= 2 || value.alter?.length > 0 || value.src?.length > 0 || value.rating?.length > 0 || value.genre?.length > 0)) {
        this.store.resetStore();
        this.queryParams = null;
        this.store.noMoreResults = false;
        this.store.offset = 0;
      } else {
        this.queryParams = value;
        this.noMoreResults = this.store.sameAsLastSearch({
          q: value.q,
          rating: value.rating ? !Array.isArray(value.rating) ? [value.rating] : [...value.rating] : null,
          alter: value.alter ? !Array.isArray(value.alter) ? [value.alter] : [...value.alter] : null,
          src: value.src ? !Array.isArray(value.src) ? [value.src] : [...value.src] : null,
          genre: value.genre ? !Array.isArray(value.genre) ? [value.genre] : [...value.genre] : null,
          limit: this.limit,
          offset: this.offset + this.limit
        });
      }
    });
    // if (this.results && this.results.length > 0 && this.offset === 0) {
    //   this.offset = this.store.offset;
    //   // this.store.noMoreResults = this.results.length >= this.offset;
    // }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.results && !changes.results.firstChange) {
      if (this.results && this.results.length > 0) {
        this.loadingChunk = false;
        this.calculateNumberOfItemsPerRow();
        this.calculateComponentHeight();
      }
    }
  }

  loadNextChunk(event?: IPageInfo): void {
    // if (!this.results || event.endIndex < this.results.length || this.loadingChunk) {
    //   return;
    // }
    if (!this.loadingChunk && !this.noMoreResults) {
      const params = { ...this.queryParams };
      params.alter = params.alter ? !Array.isArray(params.alter) ? [params.alter] : [...params.alter] : null;
      params.rating = params.rating ? !Array.isArray(params.rating) ? [params.rating] : [...params.rating] : null;
      params.src = params.src ? !Array.isArray(params.src) ? [params.src] : [...params.src] : null;
      params.genre = params.genre ? !Array.isArray(params.genre) ? [params.genre] : [...params.genre] : null;
      const same = this.store.sameAsLastSearch({
        q: params.q,
        rating: params.rating,
        alter: params.alter,
        src: params.src,
        genre: params.genre,
        offset: Number(this.offset) + Number(this.limit),
        limit: this.limit
      });
      if (!same) {
        this.loadingChunk = true;
        this.offset = Number(this.offset) + Number(this.limit);
        this.searchService.search(
          params.q,
          params.rating,
          params.alter,
          params.src,
          params.genre,
          this.offset,
          this.limit,
          (result) => {
            if (result.length > 0) {
              this.store.noMoreResults = false;
            } else {
              this.store.noMoreResults = true;
            }
            this.loadingChunk = false;
          });
      }
    }
  }

  navTo(m: any, i: number): void {
    this.store.resultCurrentIndex = window.pageYOffset;
    if (m.itemclass === this.sic.Besprechung) {
      this.router.navigate(['/besprechung', m.id, this.flimmoMeta.prettifyUrl(m.title)]);
    }
    if (m.itemclass === this.sic.Ytbesprechung) {
      this.router.navigate(['/ytbesprechung', m.id, this.flimmoMeta.prettifyUrl(m.title)]);
    }
    if (m.itemclass === this.sic.Redtext) {
      this.router.navigate(['/redtext', m.id, this.flimmoMeta.prettifyUrl(m.title)]);
    }
    if (m.itemclass === this.sic.Special) {
      this.router.navigate(['/special', m.id, this.flimmoMeta.prettifyUrl(m.title)]);
    }
  }

  private calculateNumberOfItemsPerRow(): void {
    if (this.cardContainer && this.cardContainer.nativeElement) {
      this.fillerWidth = 500;
      const containerWidth = this.cardContainer.nativeElement.clientWidth;
      const filler = this.isHandset ? this.fillerMobile : this.filler;
      const cw = !this.isHandset ? this.cardWidth : this.cardWidthMobile;
      let cardsPerRow = Math.floor(containerWidth / cw);
      if ((cardsPerRow * cw) + ((cardsPerRow - 1) * filler) >
        (containerWidth === 900 ? containerWidth : containerWidth - this.CONTAINER_TRESHOLD)) {
        cardsPerRow = cardsPerRow - 1;
      }

      this.itemsPerRow = cardsPerRow;
      if (this.results && this.results.length > 0) {
        const size = cardsPerRow - this.results.length % cardsPerRow;
        if (size > 0) {
          this.fillerCards = Array(size);
        }
      }
      this.cdr.markForCheck();
      this.cdr.detectChanges();
    }
  }

  private calculateComponentHeight(): void {
    if (this.results && this.results.length > 0 && this.itemsPerRow) {
      const ch = this.isHandset ? this.cardHeightMobile : this.cardHeight;
      this.heightNr = this.titleHeight + Math.ceil(this.results.length / this.itemsPerRow) * (ch + this.verticalPadding);
      if (this.heightNr) {
        setTimeout(() => {
          this.height = this.heightNr + 'px';
        });
      }
      this.cdr.markForCheck();
      this.cdr.detectChanges();
    }
  }

  scrollToLastPosition(): void {
    if (this.pageTopOffset) {
      window.scroll({
        top: this.pageTopOffset
      });
      this.store.resultCurrentIndex = null;
      this.cdr.detectChanges();
    }
  }

  // TODO: possible code duplication in swimplane components

  getText(srclist: Array<Source>): string {
    const text = srclist
      .sort((a, b) => this.srcSequence.indexOf(a.src) - this.srcSequence.indexOf(b.src))
      .map(value => value.srcName) // value.channel=='tv' ? '⊙ '+value.srcName : value.srcName
      .reduce((previousValue, currentValue) => previousValue + ', ' + currentValue);

    if (text.length > 55) {
      return text.substring(0, 55) + '...';
    } else {
      return text;
    }
  }

  hasSrc(m: PreviewItem): boolean {
    return (m.itemclass === this.sic.Besprechung || m.itemclass === this.sic.Ytbesprechung) && m.srclist && m.srclist.length > 0;
  }

  onImageError(event): void {
    event.target.src = Constants.PLACEHOLDER_IMAGE_WITH_PATH;
  }


  srcSequence = [
    "kino",
    "netflix",
    "amazon",
    "disney_plus",
    "kika",
    "toggo",
    "ARD Mediathek",
    "ZDF Mediathek",
    "RTLPLUS",
    "Joyn",
    "joyn",
    "youtube",
    "youtubekids",
    "tiktok",
    "instagram",
    "tv",
    "KKA",
    "RTLS",
    "Disney Channel Free TV",
    "NICK",
    "RTL",
    "RTL2",
    "PRO7",
    "SAT1",
    "K1",
    "ARD",
    "ZDF"
  ]
}
