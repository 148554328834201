import {Component} from '@angular/core';

@Component({
    selector: 'app-left-drawer',
    templateUrl: './left-drawer.component.html',
    styleUrls: ['./left-drawer.component.scss'],
    standalone: false
})
export class LeftDrawerComponent  {
}
